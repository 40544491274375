import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0a0c10', // Neon cyan for primary accents
      contrastText: '#ffffff', // White text on primary buttons
    },
    secondary: {
      main: '#ff6f61', // Neon pink for secondary accents
      contrastText: '#ffffff',
    },
    background: {
      default: '#0a0c10', // Nearly black for app background
      paper: '#0a0c10', // Slightly lighter for cards and panels
    },
    text: {
      primary: '#e0e0e0', // Bright white for primary text
      secondary: '#9e9e9e', // Muted gray for secondary text
      disabled: '#616161', // Darker gray for disabled elements
    },
    error: {
      main: '#ff5252', // Bright red for errors
    },
    warning: {
      main: '#ffb300', // Bright yellow for warnings
    },
    info: {
      main: '#40c4ff', // Bright blue for informational messages
    },
    success: {
      main: '#69f0ae', // Neon green for success
    },
    divider: 'rgba(255, 255, 255, 0.08)', // Subtle dividers
  },
  typography: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: { color: '#ffffff' },
    h2: { color: '#ffffff' },
    h3: { color: '#ffffff' },
    body1: { color: '#e0e0e0' },
    body2: { color: '#bdbdbd' },
    button: { textTransform: 'none', fontWeight: 600 }, // Bold buttons
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#00000000',
          borderRadius: '0px',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Rounded buttons
          padding: '10px 20px',
          textTransform: 'uppercase', // Standout button text
          boxShadow: '0px 4px 6px rgba(0, 188, 212, 0.4)', // Glow effect for primary buttons
          '&:hover': {
            boxShadow: '0px 8px 12px rgba(0, 188, 212, 0.6)', // Intensify glow on hover
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#e0e0e0', // Default text color
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.1)', // Subtle divider lines
        },
      },
    },
  },
});

export default theme;
