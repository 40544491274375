import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://projektearlybird-06f8eb8906dc.herokuapp.com/api' // Production backend
      : 'http://localhost:5000/api', // Development backend (adjust the port if needed)
});

export default api;
