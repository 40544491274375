import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const BacktestingModal = ({ isOpen, onClose, content }) => {
  const theme = useTheme();
  const formatValue = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    return value || 'N/A';
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(8px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: '12px',
          padding: '24px',
          maxWidth: '600px',
          width: '100%',
          color: '#FFFFFF',
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.6)',
          outline: 'none',
          margin: '16px', // Ensures proper spacing on mobile screens
          position: 'relative', // Ensures the close button stays within the modal
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: theme.palette.text.primary,
            '&:hover': {
              color: theme.palette.secondary.main, // Orange highlight on hover
            },
            zIndex: 10, // Ensures the button stays above other elements
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          Backtesting Results
        </Typography>
        <Divider sx={{ backgroundColor: '#FFFFFF', my: 2 }} />

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Mode
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              {content?.mode || 'N/A'}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Total Profit
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              ${formatValue(content?.total_profit)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Total Trades
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              {content?.total_trades || 'N/A'}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Win Rate
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              {content?.win_rate ? `${content.win_rate}%` : 'N/A'}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Average Profit per Trade
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              ${formatValue(content?.average_profit_per_trade)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Total Loss
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              ${formatValue(content?.total_loss)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Risk-Reward Ratio
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              {formatValue(content?.risk_reward_ratio)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Winning Trades
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              {content?.winning_trades || 'N/A'}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Losing Trades
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              {content?.losing_trades || 'N/A'}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="body1" sx={{ color: '#cccccc' }}>
              Final Capital
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#FFFFFF' }}
            >
              ${formatValue(content?.final_capital)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default BacktestingModal;
