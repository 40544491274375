import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Tooltip } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Logout icon
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { PROJECT_NAME } from '../constants'

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // For navigation
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the view is mobile

  useEffect(() => {
    // Check if user is logged in by checking for authToken in localStorage
    const authToken = localStorage.getItem('authToken');
    setIsLoggedIn(!!authToken); // Convert authToken to boolean
  }, [location]); // Recheck whenever the location changes (e.g., after login/logout)

  const handleToggle = () => {
    setIsExpanded((prev) => !prev); // Toggle expanded state
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove the auth token
    setIsLoggedIn(false); // Update login state
    navigate('/login'); // Redirect to the login page
  };

  const isActive = (path) => location.pathname === path; // Determine if the link is active

  // Define the paths where the Navbar should be hidden
  const hideNavbarPaths = ['/login'];

  // If the current route is in the list of paths to hide the navbar, return null
  if (hideNavbarPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <>
      {/* AppBar */}
      <AppBar
      position="static"
      sx={{
        backgroundColor: '#0000000', // Match the app background color
        boxShadow: 'none', // Remove shadow for a seamless look
      }}
    >        
    <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Spread elements
            alignItems: 'center',
          }}
        >
          {/* App Title or Mobile Title */}
          <Box
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 700,
              letterSpacing: '0.05em',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              background: 'linear-gradient(145deg, #ff9f43, #ff6f61)', // Gradient background
              WebkitBackgroundClip: 'text', // Clip background to text
              WebkitTextFillColor: 'transparent', // Make the text itself transparent
              backgroundClip: 'text', // Ensure it works on non-Webkit browsers
              color: 'transparent', // Fallback for unsupported browsers
            }}
          >
            {isMobile ? PROJECT_NAME : PROJECT_NAME}
          </Box>

          {/* Tabs + Expandable Button on the Right */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2, // Space between tabs and button
            }}
          >
            {/* Tabs */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2, // Space between icons
                transition: 'opacity 0.3s ease, transform 0.3s ease',
                opacity: isExpanded ? 1 : 0, // Fade in/out
                transform: isExpanded ? 'translateX(0)' : 'translateX(20px)', // Slide animation
                pointerEvents: isExpanded ? 'auto' : 'none', // Disable interaction when hidden
              }}
            >
            </Box>

            {/* Logout Icon Button: Only Show When Logged In */}
            {isLoggedIn && (
              <Tooltip
                title="Logout"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontWeight: 'bold', // Use bold keyword
                      background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
                      color: '#fff', // White text color
                      fontSize: '0.9rem', // Slightly larger font
                      borderRadius: '8px', // Rounded corners
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Subtle shadow
                    },
                  },
                }}
              >
                <IconButton
                  onClick={handleLogout}
                  sx={{
                    color: '#FFFFFF',
                    transition: 'transform 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      color: '#e33e3e', // Change color on hover
                      transform: 'scale(1.2)', // Scale icon on hover
                    },
                  }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            )}

            {/* Expandable Button */}
            <IconButton color="inherit" onClick={handleToggle}>
              <GridViewIcon
                sx={{
                  transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)', // Rotate when expanded
                  transition: 'transform 0.3s ease', // Smooth rotation animation
                }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
