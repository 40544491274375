import React, { useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Paper,
  IconButton,
  Button,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import api from '../utils/api';
import { useTheme } from '@mui/material/styles';

const SimulationModal = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [error, setError] = useState('');
  const [quantity, setQuantity] = useState('');
  const [risk, setRisk] = useState('');
  const [strategy, setStrategy] = useState('openingRange');

  const strategies = [
    { value: 'openingRange', label: 'Opening Range' },
    { value: 'openingReversal', label: 'Opening Reversal' },
  ];

  const handleSimulation = async () => {
    setLoading(true);
    setError('');
    setResponseMessage('');
    try {
      if (!quantity || !risk || !strategy) {
        setError('Please fill in all fields (Quantity, Risk, and Strategy).');
        setLoading(false);
        return;
      }
      const response = await api.get('/simulation', {
        params: { quantity, risk, strategy },
      });
      setResponseMessage(response.data.message);
    } catch (err) {
      console.error(err);
      setError('Failed to execute simulation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetAndClose = () => {
    setQuantity('');
    setRisk('');
    setStrategy('openingRange');
    setResponseMessage('');
    setError('');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleResetAndClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        backdropFilter: 'blur(8px)', // Blur effect for the entire modal background
      }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: '600px',
          borderRadius: '16px',
          overflow: 'hidden',
          outline: 'none', // Removes focus border when interacting with the modal
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Simulation
          </Typography>
          <IconButton
            onClick={handleResetAndClose}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': { color: theme.palette.secondary.main },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Input Section */}
        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Quantity"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: theme.palette.secondary.main },
                '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                '& input': { color: theme.palette.text.primary },
              },
              '& .MuiInputLabel-root': { color: theme.palette.text.secondary },
            }}
          />
          <TextField
            label="Risk"
            type="number"
            value={risk}
            onChange={(e) => setRisk(e.target.value)}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: theme.palette.secondary.main },
                '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                '& input': { color: theme.palette.text.primary },
              },
              '& .MuiInputLabel-root': { color: theme.palette.text.secondary },
            }}
          />
          <TextField
            label="Strategy"
            select
            value={strategy}
            onChange={(e) => setStrategy(e.target.value)}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: theme.palette.secondary.main },
                '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                '& input': { color: theme.palette.text.primary },
              },
              '& .MuiInputLabel-root': { color: theme.palette.text.secondary },
            }}
          >
            {strategies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {/* Action Section */}
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              textAlign: 'center',
              cursor: 'pointer',
              '&:hover': { boxShadow: `0 0 10px ${theme.palette.secondary.main}` },
            }}
            onClick={handleSimulation}
          >
            <PlayArrowIcon />
            <Typography>Run Simulation</Typography>
          </Paper>
        </Box>

        {/* Response Section */}
        {responseMessage && (
          <Typography
            sx={{
              textAlign: 'center',
              p: 2,
              color: theme.palette.success.main,
              fontWeight: 500,
            }}
          >
            {responseMessage}
          </Typography>
        )}
        {error && (
          <Typography
            sx={{
              textAlign: 'center',
              p: 2,
              color: theme.palette.error.main,
              fontWeight: 500,
            }}
          >
            {error}
          </Typography>
        )}

        {/* Loading Spinner */}
        {loading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark background for loading
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backdropFilter: 'blur(10px)', // Blur effect while loading
              zIndex: 1300, // Ensure the spinner overlay is above everything
            }}
          >
            <CircularProgress size={48} sx={{ color: '#fff' }} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default SimulationModal;
