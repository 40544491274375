import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send'; // Import SendIcon
import api from '../utils/api'; // Ensure this path is correct

const AnalyzeModal = ({ isOpen, onClose }) => {
  const theme = useTheme();

  const [message, setMessage] = useState(''); // Current user message input
  const [conversation, setConversation] = useState([]); // Conversation history
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Utility function to handle keyboard interactions for Papers acting as buttons
  const handleKeyPress = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      action();
    }
  };

  const handleSubmit = async () => {
    if (!message.trim()) return; // Prevent empty messages
    setIsLoading(true);

    const newMessage = { role: 'user', content: message }; // Format user's message

    // Update conversation with user's message
    setConversation((prev) => [...prev, newMessage]);

    try {
      // Send the conversation history to the API
      const response = await api.post('/prompt', {
        messages: [...conversation, newMessage],
      });

      // Append AI's response to the conversation
      const aiResponse = { role: 'assistant', content: response.data.message };
      setConversation((prev) => [...prev, aiResponse]);
      setMessage(''); // Clear input
    } catch (error) {
      console.error('Error fetching AI response:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'Failed to get a response. Please try again.',
      };
      setConversation((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        backdropFilter: 'blur(8px)', // Blur effect for the entire modal background
      }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: '500px',
          bgcolor: theme.palette.background.paper,
          color: '#FFFFFF',
          borderRadius: '16px',
          p: 4,
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.6)',
          textAlign: 'center',
          position: 'relative',
          outline: 'none', // Removes focus border when interacting with the modal
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: theme.palette.text.primary,
            '&:hover': {
              color: theme.palette.secondary.main, // Orange highlight on hover
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Modal Title */}
        <Typography
          variant="h6"
          sx={{
            mb: 3,
            fontWeight: 'bold',
            WebkitBackgroundClip: 'text',
            color: theme.palette.text.primary,
          }}
        >
          Ask a question
        </Typography>

        {/* Conversation History or Wave Animation */}
        <Box
          sx={{
            mt: 2,
            maxHeight: '300px',
            overflowY: 'auto',
            p: 2,
            bgcolor: 'transparent',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: conversation.length === 0 ? 'center' : 'flex-start',
            alignItems: conversation.length === 0 ? 'center' : 'stretch',
          }}
        >
          {conversation.length === 0 ? (
            // Show Wave Animation when there is no conversation
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
              }}
            >
              {[...Array(20)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '2px',
                    height: '20px',
                    background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
                    animation: `pulse 1.2s ${index * 0.2}s infinite ease-in-out`,
                    borderRadius: '2px',
                  }}
                />
              ))}
              {/* Animation Keyframes */}
              <style>
                {`
                  @keyframes pulse {
                    0%, 100% {
                      transform: scaleY(1);
                    }
                    50% {
                      transform: scaleY(2);
                    }
                  }
                `}
              </style>
            </Box>
          ) : (
            // Show Conversation History when there are messages
            conversation.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  textAlign: msg.role === 'user' ? 'right' : 'left',
                  mb: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: msg.role === 'user' ? theme.palette.secondary.main : '#FFFFFF',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    display: 'inline-block',
                    padding: '8px 12px',
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    maxWidth: '80%',
                  }}
                >
                  {msg.content}
                </Typography>
              </Box>
            ))
          )}
        </Box>

        {/* User Input with Send Icon */}
        <TextField
          variant="outlined"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          sx={{
            mt: 2,
            backgroundColor: 'transparent',
            borderRadius: '12px',
            border: `1px solid ${theme.palette.divider}`,
            '& .MuiInputLabel-root': {
              color: '#FFFFFF',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.divider,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSubmit}
                  edge="end"
                  color="#fff"
                  disabled={isLoading}
                  aria-label="send message"
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SendIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />

        {/* Optional: Remove the separate Submit Button below if not needed */}
        {/* 
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              p: 1,
              borderRadius: '12px',
              background: theme.palette.background.paper,
              transition: 'box-shadow 0.1s ease, transform 0.3s ease',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
              },
              color: '#fff',
            }}
            onClick={handleSubmit}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: '#FFFFFF' }} />
            ) : (
              <>
                <SendIcon />
                <Typography>Send</Typography>
              </>
            )}
          </Paper>
        </Box>
        */}
      </Box>
    </Modal>
  );
};

export default AnalyzeModal;
