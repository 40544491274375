// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { PROJECT_NAME } from '../constants';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const Login = () => {
  const theme = useTheme();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      // Simulate a short delay for demonstration purposes
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (
        (username === 'benji' && password === 'rules') ||
        (username === 'user' && password === '#eab123#')
      ) {
        localStorage.setItem('authToken', username);
        navigate('/');
      } else {
        setErrorMessage('Invalid username or password');
      }
    } catch (err) {
      console.error('Login error:', err);
      setErrorMessage('An error occurred during login. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showLogin) {
      // Prevent background scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable background scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showLogin]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        overflow: 'hidden', // Prevents scrolling
      }}
    >
      {/* IconButton on bottom center */}
      <Box
        sx={{
          position: 'fixed', // Fixed to viewport
          bottom: theme.spacing(2), // Spacing from bottom
          left: '50%', // Center horizontally
          transform: 'translateX(-50%)', // Adjust for exact centering
          zIndex: 10,
        }}
      >
        <IconButton
          onClick={() => setShowLogin(true)}
          sx={{
            color: theme.palette.text.primary,
            '&:hover': { color: theme.palette.secondary.main },
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            // Optional: Add border or shadow for better visibility
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LockOpenIcon />
        </IconButton>
      </Box>

      {/* Background text with fade-in animation */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh', // Fill the entire viewport height
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 700,
          letterSpacing: '0.05em',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '3rem',
          background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          color: 'transparent',
          textAlign: 'center',

          // Define and apply fade-in animation
          '@keyframes fadeIn': {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
          animation: 'fadeIn 2s ease-in-out forwards', // 2s duration, adjust as needed
          opacity: 0, // Start transparent until animation runs
        }}
      >
        {PROJECT_NAME}
      </Box>

      {showLogin && (
        <>
          {/* Dark overlay */}
          <Box
            onClick={() => setShowLogin(false)} // Clicking outside the card closes it
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(8px)',
              zIndex: 20,
            }}
          />

          {/* Centered login card */}
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 30,
              maxHeight: '90vh', // Prevents modal from exceeding viewport height
              overflowY: 'auto',  // Adds internal scrolling if content overflows
            }}
          >
            <Card
              sx={{
                width: '100%',
                maxWidth: '400px',
                borderRadius: '16px',
                border: `1px solid ${theme.palette.divider}`,
                background: theme.palette.background.paper,
                boxShadow: `0 4px 30px rgba(0, 0, 0, 0.1)`,
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                    }}
                  >
                    Welcome Back
                  </Typography>
                }
                sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
              />
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary, textAlign: 'center' }}
                >
                  Login to access your account
                </Typography>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  color="secondary"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: theme.palette.secondary.main },
                      '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                      '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                    },
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-input': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  color="secondary"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: theme.palette.secondary.main },
                      '&:hover fieldset': { borderColor: theme.palette.secondary.light },
                      '&.Mui-focused fieldset': { borderColor: theme.palette.secondary.main },
                    },
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-input': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />

                {/* Paper-based "Login" tile */}
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <Paper
                    elevation={2}
                    sx={{
                      width: '200px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 2,
                      borderRadius: '12px',
                      background: theme.palette.background.paper,
                      transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                      },
                    }}
                    onClick={handleLogin}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: theme.palette.text.primary }} />
                    ) : (
                      <LockOpenIcon sx={{ mb: 1 }} />
                    )}
                    <Typography>{loading ? 'Logging in...' : 'Login'}</Typography>
                  </Paper>
                </Box>

                {errorMessage && (
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      borderRadius: '8px',
                      backgroundColor: 'rgba(255, 0, 0, 0.1)',
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                      {errorMessage}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Login;
