import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CardContent } from '@mui/material';
import throttle from 'lodash/throttle';
import { useTheme } from '@mui/material/styles';

const TerminalModal = ({ logs, clearLogs }) => {
  const [showTerminal, setShowTerminal] = useState(false);
  const missingTimestamps = useRef({});
  const theme = useTheme();
  const handleAnimation = throttle(() => {
    setShowTerminal(true);
  }, 200);

  useEffect(() => {
    if (logs.length > 0) {
      const timer = setTimeout(() => {
        window.requestAnimationFrame(handleAnimation);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setShowTerminal(false);
    }
  }, [logs, handleAnimation]);

  const prettifyLog = (log, index) => {
    // Generate a timestamp if not provided
    const currentTimestamp = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
      hour12: true,
    });

    const timestampRegex = /\[(.*?)\]/g;
    const parts = log.split(' - ');
    const [metadata, message] = parts;
    const [level, timestamp] = metadata?.match(timestampRegex) || [];

    const colorMap = {
      ERROR: '#ff4d4f',
      WARNING: '#faad14',
      INFO: '#58a6ff',
      LOG: '#ff9f43',
    };
    const timestampColor = '#ff9f43';
    const levelKey = level?.replace('[', '').replace(']', '') || 'LOG';
    const levelColor = colorMap[levelKey] || '#ffffff';

    // Check if the log is missing a timestamp
    const logTimestamp =
      timestamp?.replace('[', '').replace(']', '') ||
      missingTimestamps.current[index] ||
      (missingTimestamps.current[index] = currentTimestamp);

    return (
      <Box sx={{ marginBottom: '10px' }}>
        <Box
          component="span"
          sx={{
            color: levelColor,
            fontWeight: 'bold',
          }}
        >
          {levelKey}
        </Box>
        <Box
          component="span"
          sx={{
            color: timestampColor,
            marginLeft: '8px',
            fontSize: '0.85rem',
          }}
        >
          {logTimestamp}
        </Box>
        <Box
          component="div"
          sx={{
            color: '#c9d1d9',
            marginTop: '4px',
          }}
        >
          {message || log}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        flexDirection: 'column',
        p: 3,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          backgroundColor: theme.palette.background.default,
          padding: 2,
          animation: showTerminal ? 'fadeIn 0.5s ease-in-out' : 'none',
          height: '100%',
        }}
      >
        {!showTerminal ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '300px',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', gap: '3px' }}>
              {[...Array(10)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '4px',
                    height: '20px',
                    background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
                    animation: `pulse 1.2s ${index * 0.2}s infinite ease-in-out`,
                    borderRadius: '2px',
                  }}
                />
              ))}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box
              sx={{
                fontFamily: 'monospace',
                fontSize: '0.9rem',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                color: '#c9d1d9',
                backgroundColor: theme.palette.background.paper,
                padding: 2,
                borderRadius: 2,
                maxHeight: '400px',
                overflowY: 'auto',
                boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {logs.map((log, index) => (
                <div key={index}>{prettifyLog(log, index)}</div>
              ))}
            </Box>
          </Box>
        )}
      </CardContent>

      <style>
        {`
          @keyframes pulse {
            0%, 100% {
              transform: scaleY(1);
            }
            50% {
              transform: scaleY(1.5);
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TerminalModal;
