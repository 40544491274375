import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Navbar from './components/Navbar';
import { Analytics } from '@vercel/analytics/react';
import SimulationPage from './pages/Simulation';
import NotesPage from './pages/Notes'
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import MarketPage from './pages/Market';
import { AnimatePresence, motion } from 'framer-motion';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute

function AppContent() {
  const location = useLocation();

  const hideNavbarAndFooterRoutes = ['/login'];
  const shouldHideNavbarAndFooter = hideNavbarAndFooterRoutes.includes(location.pathname);

  const pageVariants = {
    initial: { opacity: 0, x: '100%' },
    animate: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
    exit: {
      opacity: 0,
      x: '-100%',
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {!shouldHideNavbarAndFooter && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 10,
          }}
        >
          <Navbar />
        </div>
      )}

      <div
        style={{
          flex: 1,
          marginTop: !shouldHideNavbarAndFooter ? '64px' : '0',
          overflowY: 'auto',
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={location.pathname}
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            style={{ height: '100%' }}
          >
            <Routes location={location}>
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/simulation"
                element={
                  <ProtectedRoute>
                    <SimulationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notes"
                element={
                  <ProtectedRoute>
                    <NotesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/market"
                element={
                  <ProtectedRoute>
                    <MarketPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}


function App() {
  // Suppress ResizeObserver errors globally
  useEffect(() => {
    const resizeObserverErrorHandler = (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
      }
    };

    window.addEventListener('error', resizeObserverErrorHandler);
    return () =>
      window.removeEventListener('error', resizeObserverErrorHandler);
  }, []);

  return (
    <GlobalStyles>
      <Router>
        <AppContent />
        <Analytics />
      </Router>
    </GlobalStyles>
  );
}

export default App;
