import React from 'react';
import { Box, Typography, Modal, Paper, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useTheme } from '@mui/material/styles';

const BacktestingOptionModal = ({ isOpen, onClose, onSelectOption, loading }) => {
  const theme = useTheme();

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(8px)',
        }}
      >
        <Box
          sx={{
            width: '90%',
            maxWidth: '500px',
            bgcolor: theme.palette.background.paper,
            color: '#FFFFFF',
            borderRadius: '16px',
            p: 4,
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.6)',
            textAlign: 'center',
            position: 'relative',
            outline: 'none', // Removes focus border when interacting with the modal
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.secondary.main, // Orange highlight on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Title */}
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontWeight: 'bold',
              background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
            }}
          >
            Select Backtesting Option
          </Typography>

          {/* Option Cards */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
                borderRadius: '12px',
                cursor: 'pointer',
                background: theme.palette.background.default,
                transition: 'transform 0.3s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0px 0px 10px ${theme.palette.secondary.main}`,
                },
              }}
              onClick={() => onSelectOption('Opening Range')}
            >
              <BarChartIcon sx={{ fontSize: 48, color: theme.palette.text.primary, mb: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                Opening Range
              </Typography>
            </Paper>

            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
                borderRadius: '12px',
                cursor: 'pointer',
                background: theme.palette.background.default,
                transition: 'transform 0.3s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0px 0px 10px ${theme.palette.secondary.main}`,
                },
              }}
              onClick={() => onSelectOption('Opening Reversal')}
            >
              <ShowChartIcon sx={{ fontSize: 48, color: theme.palette.text.primary, mb: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                Opening Reversal
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Modal>

      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(10px)',
            zIndex: 1301,
          }}
        >
          <CircularProgress size={48} sx={{ color: '#fff' }} />
        </Box>
      )}
    </>
  );
};

export default BacktestingOptionModal;
