// src/pages/Market.jsx

import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import api from '../utils/api'; // Ensure this is correctly set up
import '../styles/styles.css'; // Optional: Custom styles

const Market = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // State variables for Weekly Analysis
  const [weeklyLoading, setWeeklyLoading] = useState(false);
  const [weeklyResponse, setWeeklyResponse] = useState('');
  const [weeklyError, setWeeklyError] = useState('');

  // State variables for Intraday Analysis
  const [intradayLoading, setIntradayLoading] = useState(false);
  const [intradayResponse, setIntradayResponse] = useState('');
  const [intradayError, setIntradayError] = useState('');

  // Handler for Weekly Analysis
  const handleWeeklyAnalysis = async () => {
    setWeeklyLoading(true);
    setWeeklyError('');
    setWeeklyResponse('');
    try {
      // Define parameters for Weekly Analysis
      const params = {
        quantity: 100, // Example quantity
        risk: 0.5,      // Example risk
        strategy: 'openingRange', // Example strategy
      };
      const response = await api.get('/strategy', { params });
      setWeeklyResponse(response.data.message || 'Weekly analysis executed successfully.');
    } catch (err) {
      console.error(err);
      setWeeklyError('Failed to execute weekly analysis. Please try again.');
    } finally {
      setWeeklyLoading(false);
    }
  };


  // Handler for Intraday Analysis
  const handleIntradayAnalysis = async () => {
    setIntradayLoading(true);
    setIntradayError('');
    setIntradayResponse('');
    try {
      // Define parameters for Intraday Analysis
      const params = {
        quantity: 50, // Example quantity
        risk: 0.3,     // Example risk
        strategy: 'intradayRange', // Example strategy
      };
      const response = await api.get('/market', { params });
      setIntradayResponse(response.data.message || 'Intraday analysis executed successfully.');
    } catch (err) {
      console.error(err);
      setIntradayError('Failed to execute intraday analysis. Please try again.');
    } finally {
      setIntradayLoading(false);
    }
  };

  // Handler to Reset and Close
  const handleResetAndClose = () => {
    // Reset all states
    setWeeklyResponse('');
    setWeeklyError('');
    setIntradayResponse('');
    setIntradayError('');
    navigate(-1); // Navigate back
  };

  /**
   * Updated renderResponse function to accurately capture preamble, numbered sections, and conclusion
   */
  const renderResponse = (response) => {
    if (!response) return null;

    // Regex to match all strategy sections (e.g., "Strategy 3:", "Strategy 4:", etc.)
    const sectionRegex = /Strategy\s+\d+:\s+[\s\S]+?(?=Strategy\s+\d+:|$)/g;
    const sections = response.match(sectionRegex) || [];

    // Extract preamble: text before the first strategy section
    const preambleEndIndex = sections.length > 0 ? response.indexOf(sections[0]) : 0;
    let preamble = response.substring(0, preambleEndIndex).trim();

    // Split into lines and filter out unwanted patterns
    preamble = preamble
      .split('\n')
      .filter(line => !/^\d+\.\s*\*+/.test(line))
      .join('\n')
      .trim();

    // Extract conclusion: text after the last strategy section
    const lastSection = sections[sections.length - 1];
    const lastSectionEndIndex = lastSection
      ? response.lastIndexOf(lastSection) + lastSection.length
      : 0;
    const conclusion = response.substring(lastSectionEndIndex).trim();

    return (
      <Box>
        {/* Render preamble if it exists */}
        {preamble && (
          <Typography variant="body1" color="textPrimary" gutterBottom>
            {preamble}
          </Typography>
        )}

        {/* Render each parsed strategy section */}
        {sections.map((section, index) => {
          // Extract the strategy title
          const titleMatch = section.match(/Strategy\s+\d+:\s+([^*]+)\*\*/);
          const title = titleMatch ? titleMatch[1].trim() : `Strategy ${index + 1}`;

          // Extract Description
          const descriptionMatch = section.match(/\*\*Description:\*\*\s*([^*]+)(?=\*\*|$)/);
          const description = descriptionMatch ? descriptionMatch[1].trim() : '';

          // Extract Relevant Indicators
          const indicatorsMatch = section.match(/\*\*Relevant Indicators:\*\*\s*([^*]+)(?=\*\*|$)/);
          const indicators = indicatorsMatch ? indicatorsMatch[1].trim() : '';

          return (
            <Box key={index} sx={{ mb: 2 }}>
              {/* Render title */}
              <Typography variant="subtitle1" color="textPrimary" fontWeight="bold">
                {`Strategy ${index + 1}: ${title}`}
              </Typography>

              {/* Render Description if it exists */}
              {description && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                  <strong>Description:</strong> {description}
                </Typography>
              )}

              {/* Render Relevant Indicators if they exist */}
              {indicators && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                  <strong>Relevant Indicators:</strong> {indicators}
                </Typography>
              )}

              {/* Add divider except for the last item */}
              {index < sections.length - 1 && <Divider sx={{ mt: 2 }} />}
            </Box>
          );
        })}

        {/* Render conclusion if it exists */}
        {conclusion && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              <strong>Conclusion:</strong> {conclusion}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
      }}
    >
      {/* Header Section with Close Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <IconButton
          onClick={handleResetAndClose}
          sx={{
            color: theme.palette.text.primary,
            '&:hover': { color: theme.palette.secondary.main },
          }}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {/* Two-column layout container */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* Stragegy Analysis Card based on weekly data*/}
          <Card
            sx={{
              flex: '1 1 300px',
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <CardHeader
              title="Market (Past Week) Strategy Analysis"
              action={
                <IconButton
                  onClick={handleWeeklyAnalysis}
                  disabled={weeklyLoading}
                  sx={{
                    color: theme.palette.text.main,
                  }}
                  aria-label="Execute Weekly Analysis"
                >
                  {weeklyLoading ? (
                    <CircularProgress size={24} sx={{ color: theme.palette.secondary.main }} />
                  ) : (
                    <PlayArrowIcon />
                  )}
                </IconButton>
              }
              sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            />
            <CardContent>
              {/* Response Message */}
              {weeklyResponse && (
                <Box
                  sx={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    p: 2,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '8px',
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  {renderResponse(weeklyResponse)}
                </Box>
              )}
              {/* Error Message */}
              {weeklyError && (
                <Typography
                  sx={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  {weeklyError}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Intraday Analysis Card */}
          <Card
            sx={{
              flex: '1 1 300px',
              borderRadius: '16px',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <CardHeader
              title="Market Analysis"
              action={
                <IconButton
                  onClick={handleIntradayAnalysis}
                  disabled={intradayLoading}
                  sx={{
                    color: theme.palette.text.main,
                  }}
                  aria-label="Execute Intraday Analysis"
                >
                  {intradayLoading ? (
                    <CircularProgress size={24} sx={{ color: theme.palette.secondary.main }} />
                  ) : (
                    <PlayArrowIcon />
                  )}
                </IconButton>
              }
              sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            />
            <CardContent>
              {/* Response Message */}
              {intradayResponse && (
                <Box
                  sx={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    p: 2,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '8px',
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  {renderResponse(intradayResponse)}
                </Box>
              )}
              {/* Error Message */}
              {intradayError && (
                <Typography
                  sx={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  {intradayError}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Box>

        {/* Optional: Additional Sections or Components Can Be Added Here */}
      
      </Box>
    </Box>
  );
};

export default Market;
