import React, { useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Paper,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/ClearAllRounded';
import api from '../utils/api';
import { useTheme } from '@mui/material/styles';

const TokenModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  inputValue,
  onInputChange,
  followUpResponse,
  setFollowUpResponse, // Ensure this is passed as a prop to control followUpResponse state
  authorizeLink,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const theme = useTheme();

  const handleSaveResponse = () => {
    setIsSaving(true);

    api
      .post('/data-store', { response: followUpResponse })
      .then(() => {
        setSaveMessage('Response saved successfully!');
        setIsSaving(false);
      })
      .catch((err) => {
        console.error(err);
        setSaveMessage('Failed to save response.');
        setIsSaving(false);
      });
  };

  const handleClearLog = () => {
    setFollowUpResponse(''); // Clear the response log
    setSaveMessage(''); // Clear the save message
  };

  const disabledBackground = '#050608';
  const disabledTextColor = '#444444';

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        backdropFilter: 'blur(8px)', // Blur effect for the entire modal background
      }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: '600px',
          borderRadius: '16px',
          overflow: 'hidden',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Box>
            {/* Clear Log Icon */}
            <IconButton
              onClick={followUpResponse ? handleClearLog : undefined}
              sx={{
                color: followUpResponse ? theme.palette.text.primary : '#444444', // Disabled state color
                cursor: followUpResponse ? 'pointer' : 'not-allowed', // Show pointer only if enabled
                '&:hover': followUpResponse && {
                  color: theme.palette.secondary.main, // Orange highlight
                },
              }}
              disabled={!followUpResponse} // Disable the button if there's no response
            >
              <DeleteIcon sx={{ fontSize: '24px' }} /> {/* Set size explicitly */}
            </IconButton>
            {/* Close Icon */}
            <IconButton
              onClick={onClose}
              sx={{
                color: theme.palette.text.primary,
                '&:hover': {
                  color: theme.palette.secondary.main, // Orange highlight on hover
                },
              }}
            >
              <CloseIcon sx={{ fontSize: '24px' }} /> {/* Match size with Clear Log */}
            </IconButton>
          </Box>
        </Box>



        {/* Action Cards Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            p: 3,
            gap: 2,
          }}
        >
          {/* Authorize Link Card */}
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              borderRadius: '12px',
              border: `0px solid ${theme.palette.secondary.main}`,
              background: theme.palette.background.paper,
              transition: 'box-shadow 0.1s ease, transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
              },
            }}
            onClick={() => authorizeLink && window.open(authorizeLink, '_blank')}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 1,
                fontSize: '2rem',
                color: theme.palette.text.primary,
              }}
            >
              <LinkIcon />
            </Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '0.9rem' }}>
              Authorize
            </Typography>
          </Paper>

          {/* Submit URL Card */}
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              borderRadius: '12px',
              background: inputValue ? theme.palette.background.paper : disabledBackground,
              transition: 'box-shadow 0.1s ease, transform 0.3s ease',
              '&:hover': inputValue && {
                transform: 'scale(1.05)',
                boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
              },
              cursor: inputValue ? 'pointer' : 'not-allowed',
            }}
            onClick={inputValue ? onSubmit : undefined}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 1,
                fontSize: '2rem',
                color: inputValue ? theme.palette.text.primary : disabledTextColor,
              }}
            >
              <SendIcon />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                fontSize: '0.9rem',
                color: inputValue ? theme.palette.text.primary : disabledTextColor,
              }}
            >
              Submit URL
            </Typography>
          </Paper>

          {/* Save Response Card */}
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              borderRadius: '12px',
              background: followUpResponse ? theme.palette.background.paper : disabledBackground,
              transition: 'box-shadow 0.1s ease, transform 0.3s ease',
              '&:hover': followUpResponse && {
                transform: 'scale(1.05)',
                boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
              },
              cursor: followUpResponse ? 'pointer' : 'not-allowed',
            }}
            onClick={followUpResponse ? handleSaveResponse : undefined}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 1,
                fontSize: '2rem',
                color: followUpResponse ? theme.palette.text.primary : disabledTextColor,
              }}
            >
              <SaveIcon />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                fontSize: '0.9rem',
                color: followUpResponse ? theme.palette.text.primary : disabledTextColor,
              }}
            >
              Save Response
            </Typography>
          </Paper>
        </Box>

        {/* Input Section */}
        <Box sx={{ p: 3 }}>
          <TextField
            variant="outlined"
            color="#fff"
            label="Enter URL"
            placeholder="Paste your URL here..."
            value={inputValue}
            onChange={onInputChange}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.secondary.main,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.secondary.light,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.secondary.main,
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.primary,
              },
            }}
          />
        </Box>
        {/* Terminal Section */}
        <Box
          sx={{
            p: 3,
            mt: 2,
            mr: 2,
            bgcolor: theme.palette.background.default,
            color: theme.palette.text.primary,
            maxHeight: '300px', // Adjust height for scrollable area
            overflowY: 'auto',
            position: 'relative', // Enable positioning of the icon within the terminal
          }}
        >
          {/* Terminal Output */}
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              textAlign: 'center',
            }}
          >
            {followUpResponse || 'No output yet...'}
          </Typography>
        </Box>
        {/* Save Message Section */}
        {saveMessage && (
          <Box
            sx={{
              mt: 2,
              mb: 2,
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: saveMessage.includes('successfully') ? theme.palette.secondary.light : 'red',
                fontWeight: 500,
              }}
            >
              {saveMessage}
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default TokenModal;
