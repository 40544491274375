import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import '../styles/styles.css';

const Notes = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // State variables
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState({ id: null, content: '', date: new Date() });
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch all notes from localStorage on component mount
  useEffect(() => {
    fetchNotes();
  }, []);

  // Function to fetch notes from localStorage
  const fetchNotes = () => {
    setLoading(true);
    try {
      const storedNotes = JSON.parse(localStorage.getItem('notes')) || [];
      // Convert date strings back to Date objects
      const parsedNotes = storedNotes.map(note => ({
        ...note,
        date: new Date(note.date),
      }));
      setNotes(parsedNotes);
    } catch (err) {
      console.error(err);
      setError('Failed to load notes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to save notes to localStorage
  const saveNotesToLocalStorage = (updatedNotes) => {
    try {
      localStorage.setItem('notes', JSON.stringify(updatedNotes));
    } catch (err) {
      console.error(err);
      setError('Failed to save notes. Please try again.');
    }
  };

  // Handler for adding a new note
  const handleAddNote = () => {
    setCurrentNote({ id: null, content: '', date: new Date() });
    setIsEditing(true);
  };

  // Handler for editing an existing note
  const handleEditNote = (note) => {
    setCurrentNote({ ...note, date: new Date(note.date) });
    setIsEditing(true);
  };

  // Handler for deleting a note
  const handleDeleteNote = (note) => {
    setNoteToDelete(note);
    setOpenDeleteDialog(true);
  };

  // Confirm deletion
  const confirmDeleteNote = () => {
    setLoading(true);
    try {
      const updatedNotes = notes.filter(note => note.id !== noteToDelete.id);
      setNotes(updatedNotes);
      saveNotesToLocalStorage(updatedNotes);
      setSuccess('Note deleted successfully.');
    } catch (err) {
      console.error(err);
      setError('Failed to delete note. Please try again.');
    } finally {
      setLoading(false);
      setOpenDeleteDialog(false);
      setNoteToDelete(null);
    }
  };

  // Handler for saving a note (add or edit)
  const handleSaveNote = () => {
    const { id, content, date } = currentNote;
    if (!content.trim()) {
      setError('Note content cannot be empty.');
      return;
    }

    setLoading(true);
    try {
      let updatedNotes;
      if (id) {
        // Edit existing note
        updatedNotes = notes.map(note =>
          note.id === id ? { ...note, content, date } : note
        );
        setSuccess('Note updated successfully.');
      } else {
        // Add new note
        const newNote = {
          id: Date.now(), // Simple unique ID based on timestamp
          content,
          date,
        };
        updatedNotes = [...notes, newNote];
        setSuccess('Note added successfully.');
      }
      setNotes(updatedNotes);
      saveNotesToLocalStorage(updatedNotes);
      setIsEditing(false);
      setCurrentNote({ id: null, content: '', date: new Date() });
    } catch (err) {
      console.error(err);
      setError('Failed to save note. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handler for closing the add/edit dialog
  const handleCloseEdit = () => {
    setIsEditing(false);
    setCurrentNote({ id: null, content: '', date: new Date() });
  };

  // Handler for closing snackbars
  const handleCloseSnackbar = () => {
    setError('');
    setSuccess('');
  };

  // Utility function to handle keyboard interactions for Papers acting as buttons
  const handleKeyPress = (event, onClick) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
      }}
    >
      {/* Header Section with Close Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            color: theme.palette.text.primary,
            '&:hover': { color: theme.palette.secondary.main },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {/* Notes Management Section */}
        <Card
          sx={{
            borderRadius: '16px',
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <CardHeader
            title="Manage Your Notes"
            sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
          />
          <CardContent>
            {/* Add Note Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  p: 1.5,
                  borderRadius: '12px',
                  background: theme.palette.background.paper,
                  transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                  },
                  color: '#fff',
                }}
                onClick={handleAddNote}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => handleKeyPress(e, handleAddNote)}
              >
                <AddIcon />
                <Typography>Add Note</Typography>
              </Paper>
            </Box>

            {/* Notes List */}
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            ) : notes.length === 0 ? (
              <Typography variant="body1" color="textSecondary" align="center">
                No notes available. Click "Add Note" to create one.
              </Typography>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                {notes.map((note) => (
                  <Paper
                    key={note.id}
                    elevation={2}
                    sx={{
                      p: 2,
                      borderRadius: '16px',
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '12px',
                      background: theme.palette.background.paper,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                        {note.content}
                      </Typography>
                      <Box>
                        <IconButton
                          onClick={() => handleEditNote(note)}
                          sx={{ color: theme.palette.text.main }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteNote(note)}
                          sx={{ color: theme.palette.error.main }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                      Date: {new Date(note.date).toLocaleDateString()}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>

        {/* Feedback Section */}
        {error && (
          <Snackbar
            open={Boolean(error)}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
        {success && (
          <Snackbar
            open={Boolean(success)}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}

        {/* Add/Edit Note Dialog */}
        <Dialog 
            open={isEditing} 
            onClose={handleCloseEdit} 
            fullWidth 
            maxWidth="sm"
            PaperProps={{
                sx: {
                bgcolor: theme.palette.background.paper,
                },
            }}
            sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                backdropFilter: 'blur(8px)', // Blur effect for the entire modal background
            }}
        >
          <DialogTitle>{currentNote.id ? 'Edit Note' : 'Add Note'}</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 1,
              }}
            >
              {/* Note Content */}
              <TextField
                label="Note Content"
                multiline
                rows={4}
                value={currentNote.content}
                onChange={(e) =>
                  setCurrentNote({ ...currentNote, content: e.target.value })
                }
                variant="outlined"
                fullWidth
              />

              {/* Note Date */}
              <DatePicker
                label="Date"
                value={currentNote.date}
                onChange={(newValue) => {
                  setCurrentNote({ ...currentNote, date: newValue });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {/* Cancel Button */}
            <Paper
              elevation={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                p: 1,
                borderRadius: '12px',
                background: theme.palette.background.paper,
                transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                },
                color: '#fff',
              }}
              onClick={handleCloseEdit}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => handleKeyPress(e, handleCloseEdit)}
            >
              <Typography>Cancel</Typography>
            </Paper>

            {/* Save Button */}
            <Paper
              elevation={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                p: 1,
                borderRadius: '12px',
                background: theme.palette.background.paper,
                transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                },
                color: '#fff',
              }}
              onClick={handleSaveNote}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => handleKeyPress(e, handleSaveNote)}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: theme.palette.text.primary }} />
              ) : (
                <>
                  <Typography>Save</Typography>
                </>
              )}
            </Paper>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                bgcolor: theme.palette.background.paper,
                },
            }}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                backdropFilter: 'blur(8px)', // Blur effect for the entire modal background
            }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this note? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            {/* Cancel Button */}
            <Paper
              elevation={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                p: 1,
                borderRadius: '12px',
                background: theme.palette.background.paper,
                transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                },
              }}
              onClick={() => setOpenDeleteDialog(false)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => handleKeyPress(e, () => setOpenDeleteDialog(false))}
            >
              <Typography>Cancel</Typography>
            </Paper>

            {/* Delete Button */}
            <Paper
              elevation={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                p: 1,
                borderRadius: '12px',
                background: theme.palette.background.paper,
                transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                },
                color: '#fff',
              }}
              onClick={confirmDeleteNote}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => handleKeyPress(e, confirmDeleteNote)}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: theme.palette.text.primary }} />
              ) : (
                <>
                  <Typography>Delete</Typography>
                </>
              )}
            </Paper>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Notes;
