import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Fab,
  IconButton,
  Fade,
} from '@mui/material';
import {
  PlayCircleOutline as PlayCircleOutlineIcon,
  Assignment as AssignmentIcon,
  AutoGraph as AutoGraphIcon,
  Refresh as RefreshIcon,
  LockOpen as LockOpenIcon,
  CalendarToday as CalendarTodayIcon,
  SmartToy as SmartToyIcon, // Bot icon
  ClearAllRounded as DeleteIcon,
} from '@mui/icons-material';

import TokenModal from '../modals/TokenModal';
import TerminalModal from '../modals/TerminalModal';
import BacktestingModal from '../modals/BacktestingModal';
import BacktestingOptionModal from '../modals/BacktestingOptionModal';
import AnalyzeModal from '../modals/AnalyzeModal';
import SimulationModal from '../modals/SimulationModal';
import '../styles/styles.css';
import api from '../utils/api';

const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // Modal States
  const [isSimulationModalOpen, setIsSimulationModalOpen] = useState(false);
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [isBacktestingModalOpen, setIsBacktestingModalOpen] = useState(false);
  const [isBacktestingOptionModalOpen, setIsBacktestingOptionModalOpen] = useState(false);
  const [isAnalyzeModalOpen, setIsAnalyzeModalOpen] = useState(false);

  // User Input and Responses
  const [userInputUrl, setUserInputUrl] = useState('');
  const [followUpResponse, setFollowUpResponse] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  // Token Refresh State
  const [refreshTokenCompleted, setRefreshTokenCompleted] = useState(false);

  // Logs Management
  const [logs, setLogs] = useState([]);

  // Backtesting Content
  const [backtestingContent, setBacktestingContent] = useState('');

  // Loading and Completion States
  const [loadingStates, setLoadingStates] = useState({});
  const [completedStates, setCompletedStates] = useState({});
  const [loading, setLoading] = useState(false); // For Backtesting Option Modal

  // Animation States
  const [cardsVisible, setCardsVisible] = useState(false);
  const [cardsLoaded, setCardsLoaded] = useState(false);

  // User Authentication (if needed)
  const username = localStorage.getItem('authToken');

  // Handle ResizeObserver Errors
  useEffect(() => {
    const handleResizeObserverError = (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
      }
    };

    window.addEventListener('error', handleResizeObserverError);
    return () => window.removeEventListener('error', handleResizeObserverError);
  }, []);

  // Trigger Card Animation on Mount
  useEffect(() => {
    setCardsVisible(true);

    // Calculate total animation time based on number of cards
    const cardCount = cards.length;
    const perCardDelay = 200; // ms
    const fadeInDuration = 1000; // ms
    const totalDelay = (cardCount - 1) * perCardDelay + fadeInDuration;

    const timer = setTimeout(() => {
      setCardsLoaded(true);
    }, totalDelay);

    return () => clearTimeout(timer);
  }, []);

  // Log Management Functions
  const addLog = (log) => setLogs((prevLogs) => [...prevLogs, log]);
  const clearLogs = () => setLogs([]);

  // API Request Handler
  const handleApiRequest = useCallback(async (card, option = null) => {
    const { endpoint } = card;
    setLoadingStates((prev) => ({ ...prev, [endpoint]: true }));
    const MIN_LOADING_DURATION = 500; // ms
    const startTimestamp = Date.now();

    try {
      const config = option ? { params: { option } } : {};
      const response = await api.get(endpoint, config);
      const message = response.data.message;
      setResponseMessage(message);
      addLog(`API Request Success: ${endpoint} - ${message}`);

      // Handle specific endpoints
      switch (endpoint) {
        case '/tokens':
          setIsTokenModalOpen(true);
          break;
        case '/refresh-tokens':
          setRefreshTokenCompleted(true);
          addLog('Refresh token action completed successfully');
          setTimeout(() => setRefreshTokenCompleted(false), 5000);
          break;
        case '/backtesting':
          let rawContent = response.data.message;
          if (rawContent && typeof rawContent === 'string') {
            rawContent = JSON.parse(
              rawContent.replace(/'/g, '"').replace(/np\.float64\(/g, '').replace(/\)/g, '')
            );
          }
          setBacktestingContent(rawContent);
          setIsBacktestingModalOpen(true);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
      addLog(`API Request Error: ${endpoint} - ${error.message}`);
    } finally {
      const elapsedTime = Date.now() - startTimestamp;
      const delay = Math.max(0, MIN_LOADING_DURATION - elapsedTime);
      setTimeout(() => {
        setLoadingStates((prev) => ({ ...prev, [endpoint]: false }));
        setCompletedStates((prev) => ({ ...prev, [endpoint]: true }));
        setTimeout(() => {
          setCompletedStates((prev) => ({ ...prev, [endpoint]: false }));
        }, 1000);
      }, delay);
    }
  }, []);

  // Follow-Up Request Handler
  const handleFollowUpRequest = async () => {
    const followUpEndpoint = '/authorize';
    const data = { url: userInputUrl };
    try {
      const response = await api.post(followUpEndpoint, data);
      setFollowUpResponse(response.data.message);
      addLog(`Authorization completed: ${response.data.message}`);
    } catch (error) {
      console.error(error);
      setFollowUpResponse('Error: Unable to complete follow-up request');
      addLog('Error: Unable to complete follow-up request');
    }
  };

  // Card Click Handler
  const handleCardClick = (card) => {
    const { endpoint } = card;
    if (endpoint === '/backtesting') {
      setIsBacktestingOptionModalOpen(true);
    } else if (endpoint === '/simulation') {
      navigate('/simulation');
    } else if (endpoint === '/notes') {
      navigate('/notes');
    } else if (endpoint === '/market') {
      navigate('/market');
    } else {
      handleApiRequest(card);
    }
  };

  // Backtesting Option Selection Handler
  const handleBacktestingOptionSelect = async (option) => {
    setLoading(true);
    setIsBacktestingOptionModalOpen(false);
    try {
      await handleApiRequest({ endpoint: '/backtesting' }, option);
      console.log('Backtesting initiated successfully');
    } catch (error) {
      console.error('Error during backtesting:', error);
      alert('Failed to start backtesting. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Define Card Details
  const cards = [
    { title: 'Market Analyzer', endpoint: '/market', icon: <PlayCircleOutlineIcon /> },
    { title: 'Simulation', endpoint: '/simulation', icon: <AutoGraphIcon /> },
    { title: 'Backtesting', endpoint: '/backtesting', icon: <CalendarTodayIcon /> },
    { title: 'Tokens', endpoint: '/tokens', isToken: true, icon: <LockOpenIcon /> },
    { title: 'Notes', endpoint: '/notes', icon: <AssignmentIcon /> },
    { title: 'Refresh Tokens', endpoint: '/refresh-tokens', icon: <RefreshIcon /> },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: '#FFFFFF',
        overflowX: 'hidden',
        width: '100%',
        position: 'relative',
        transition: 'backdrop-filter 0.3s ease, background 0.3s ease',
      }}
    >
      <Container
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: 4,
          px: isSmallScreen ? 2 : 4,
          paddingBottom: '0px',
        }}
      >
        {/* Card Grid */}
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ marginBottom: '20px' }}
        >
          {cards.map((card, index) => (
            <Grid item xs={6} sm={4} md={3} key={card.endpoint}>
              <Fade
                in={cardsVisible}
                timeout={{
                  enter: 1000,
                  exit: 500,
                }}
                style={{ transitionDelay: `${index * 200}ms` }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                    borderRadius: '12px',
                    background: theme.palette.background.paper,
                    transition: 'box-shadow 0.1s ease, transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: `0px 0px 6px 2px ${theme.palette.secondary.main}`,
                    },
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCardClick(card)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 1,
                      fontSize: '2rem',
                      color: completedStates[card.endpoint]
                        ? '#4caf50' // Green for completed
                        : loadingStates[card.endpoint]
                        ? '#ff9800' // Orange for loading
                        : '#fff', // Default color
                    }}
                  >
                    {card.icon}
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 500,
                      fontSize: '0.9rem',
                    }}
                  >
                    {completedStates[card.endpoint]
                      ? 'Completed'
                      : loadingStates[card.endpoint]
                      ? 'Loading...'
                      : card.title}
                  </Typography>
                </Paper>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Clear Logs Button */}
      {logs.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 84, // Align with FAB
            zIndex: 1000,
            background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
            borderRadius: '50%',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '56px',
            width: '56px',
          }}
        >
          <IconButton
            onClick={clearLogs}
            sx={{
              color: '#ffffff',
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            <DeleteIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      )}

      {/* Floating Action Button */}
      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          background: 'linear-gradient(145deg, #ff9f43, #ff6f61)',
          zIndex: 1000,
          '&:hover': {
            background: 'linear-gradient(145deg, #cc7e35, #cc4e4d)',
          },
        }}
        onClick={() => setIsAnalyzeModalOpen(true)}
      >
        <SmartToyIcon />
      </Fab>

      {/* Modals */}
      <TokenModal
        isOpen={isTokenModalOpen}
        onClose={() => setIsTokenModalOpen(false)}
        onSubmit={handleFollowUpRequest}
        title="Tokens"
        inputValue={userInputUrl}
        onInputChange={(e) => setUserInputUrl(e.target.value)}
        followUpResponse={followUpResponse}
        setFollowUpResponse={setFollowUpResponse}
        authorizeLink={
          responseMessage && responseMessage.startsWith('https') ? responseMessage : null
        }
      />

      <SimulationModal
        isOpen={isSimulationModalOpen}
        onClose={() => setIsSimulationModalOpen(false)}
      />

      <TerminalModal logs={logs} clearLogs={clearLogs} cardsLoaded={cardsLoaded} />

      <BacktestingModal
        isOpen={isBacktestingModalOpen}
        onClose={() => setIsBacktestingModalOpen(false)}
        content={backtestingContent}
      />

      <BacktestingOptionModal
        isOpen={isBacktestingOptionModalOpen}
        onClose={() => setIsBacktestingOptionModalOpen(false)}
        onSelectOption={handleBacktestingOptionSelect}
        loading={loading}
      />

      <AnalyzeModal
        isOpen={isAnalyzeModalOpen}
        onClose={() => setIsAnalyzeModalOpen(false)}
      />
    </Box>
  );
};

export default Home;
